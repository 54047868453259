<!-- ================:=========================================================================
  File Name: EditDomain.vue
  Description: User Edit Page

========================================================================================== -->

<template>
  <div id="assignment-new">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Details" icon-pack="feather" icon="icon-edit">
            <div class="tab-text">
              <assignment-details-tab
                :data="assignmentData"
                v-on:updateAssignmentData="assignmentDataUpdate"
                v-on:insertAssignmentData="createAssignment"
              />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import AssignmentDetailsTab from './AssignmentDetailsTab.vue';

export default {
  components: {
    AssignmentDetailsTab,
  },
  data() {
    return {
      assignmentData: {
        id: undefined,
        name: undefined,
        thumbnail: undefined,
        rangeStart: undefined,
        rangeEnd: undefined,
        questions: undefined,
        timer: undefined,
        shuffle: undefined,
        status: undefined,
        tags: undefined,
        bank: undefined,
        mode: undefined,
      },

      bankId: null,

      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      //tab change
    },
  },
  mounted() {
    if (!this.$route.query.bank) {
      this.$router.push('/a/examedge/banks');
    }

    this.$http
      .get(`examedge/bank/${this.$route.query.bank}`)
      .then((response) => {
        if (response.status === 200) {
          this.bankId = response.data.id;
        }
      })
      .catch((error) => {
        this.$router.push('/a/examedge/banks');
      });
  },
  methods: {
    assignmentDataUpdate(data) {
      this.assignmentData = data;
    },
    createAssignment() {
      const pl = {
        ...this.assignmentData,
        bank: this.bankId,
        rangeStart: parseInt(this.assignmentData.rangeStart),
        rangeEnd: parseInt(this.assignmentData.rangeEnd),
        questions: parseInt(this.assignmentData.questions),
        timer: parseInt(this.assignmentData.timer),
        minScore: this.assignmentData.minScore === '' ? null : parseInt(this.assignmentData.minScore),
        status: this.assignmentData.status === undefined ? 'active' : this.assignmentData.status,
      };

      if (pl.name === undefined) {
        return this.$vs.notify({
          title: 'Failed to create assignment',
          text: 'Please provide a Assignment Name',
          color: 'danger',
          position: 'top-right',
        });
      }

      this.$vs.loading();

      this.$http
        .post('examedge/assignment', pl)
        .then((response) => {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Created assignment successfully',
            text: `New local assignment ${pl.name} has been created.`,
            color: 'success',
            position: 'top-right',
          });

          if (response.status === 200) {
            const data = response.data;
            this.$router.push(`/a/examedge/assignments/${data.id}`);
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while creating this assignment';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to create assignment',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
};
</script>
