<template>
  <div id="assignment-create-details-tab">
    <div class="vx-col w-full">
      <div class="vx-row">
        <div class="vx-col lg:w-1/2 sm:w-1/1 w-full mb-2">
          <vs-input class="w-full" label-placeholder="Assignment Name" v-model="dataLocal.name" @change="assignmentUpdateData" />
        </div>
        <div class="vx-col lg:w-1/2 sm:w-1/1 w-full mb-2">
          <vs-select class="w-full mt-5" placeholder="Assignment Status" v-model="dataLocal.status" @change="assignmentUpdateData">
            <vs-select-item :key="index" :value="option" :text="option | capitalize" v-for="(option, index) in statusOptions" />
          </vs-select>
        </div>
      </div>
      <div class="vx-row mb-4">
        <div class="vx-col w-full">
          <vs-input class="w-full" label-placeholder="Thumbnail URL" v-model="dataLocal.thumbnail" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mt-2">
          <vs-textarea
            label="Assignment Description"
            counter="400"
            :counter-danger.sync="description_counter"
            height="100px"
            v-model="dataLocal.description"
            @change="assignmentUpdateData"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mb-4">
            <p>Quiz Mode</p>
            <vs-radio
              color="white"
              v-model="dataLocal.type"
              :vs-value="`study`"
              vs-name="mode"
              class="p-2 font-bold rounded-lg bg-primary mr-2"
              >Study Mode
            </vs-radio>
            <vs-radio color="white" v-model="dataLocal.type" :vs-value="`test`" vs-name="mode" class="p-2 font-bold rounded-lg bg-primary"
              >Test Mode
            </vs-radio>
          </div>

          <div class="flex justify-items-auto items-center gap-6 popup-input no-number-btn mb-8">
            <div>
              <p>Range Start</p>
              <vs-input-number v-model="dataLocal.rangeStart" type="number" min="1" />
            </div>
            <div>
              <p>Range End</p>
              <vs-input-number hide-spin-buttons v-model="dataLocal.rangeEnd" type="number" min="1" />
            </div>
            <div>
              <p>Questions</p>
              <vs-input-number hide-spin-buttons v-model="dataLocal.questions" type="number" min="1" />
            </div>
            <div>
              <p>Timer</p>
              <vs-input-number hide-spin-buttons v-model="dataLocal.timer" type="number" min="0" :max="720" />
            </div>
            <div>
              <p>Min Score</p>
              <vs-input-number hide-spin-buttons v-model="dataLocal.minScore" type="number" min="0" :max="100" />
            </div>
            <div>
              <p class="mb-2">Shuffle</p>
              <vs-switch color="success" v-model="dataLocal.shuffle" />
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button color="success" type="filled" class="mb-2 mr-2" @click="submitData">Create Assignment </vs-button>
          <vs-button color="warning" type="border" class="mb-2 ml-2" @click="resetData">Reset </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      retailer_options: [],
      description_counter: null,

      statusOptions: ['active', 'hidden', 'disabled'],
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    assignmentUpdateData() {
      this.$emit('updateAssignmentData', this.dataLocal);
    },
    submitData() {
      this.$emit('insertAssignmentData', this.dataLocal);
    },
    resetData() {
      this.dataLocal = {
        id: undefined,
        name: undefined,
        thumbnail: undefined,
        rangeStart: undefined,
        rangeEnd: undefined,
        questions: undefined,
        timer: undefined,
        shuffle: undefined,
        status: undefined,
        mode: undefined,
      };
      this.assignmentUpdateData();
    },
  },
};
</script>

<style scoped></style>
